import type { SanityBlock } from '@sanity/client';
import { CloudinaryImageType, CloudinaryVideoType } from './blockTypes';
import { ExternalLinkType } from './externalLinkType';
import { InternalLinkType } from './internalLinkType';
import { MembershipBenefitReference } from './membershipBenefitReferenceType';
import { QnaFeedBlockType } from './qnaFeedBlockType';

export enum LayoutVariant {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  FAQ = 'faq',
}

export type FeaturedBenefitBlockType = {
  title: SanityBlock[];
  tag: string;
  steps: FeaturedBenefitStep[];
  image?: CloudinaryImageType;
  illustration?: boolean;
  layoutVariant: LayoutVariant;
  colorVariant: ColorVariant;
  countdownTo?: string;
  withBenefitShowcase?: boolean;
  description?: string;
  primaryCta?: {
    text?: string;
    internalLink?: InternalLinkType;
    externalLink?: ExternalLinkType;
  };
  secondaryCta?: {
    text?: string;
    internalLink?: InternalLinkType;
    externalLink?: ExternalLinkType;
  };
  questionsAndAnswersBlock?: QnaFeedBlockType;
  video?: CloudinaryVideoType;
  membershipBenefits?: MembershipBenefitReference[];
};

export enum ColorVariant {
  signature = 'signature',
  secondary = 'secondary',
  spruce = 'spruce',
  outline = 'outline',
}

export type FeaturedBenefitStep = {
  title: string;
  description: string;
  stepImage: CloudinaryImageType;
  illustration?: boolean;
  _key: string;
};
